import createPersistedState from 'vuex-persistedstate'
import dayjs from '@shared/services/DayJS'

export default {
  state: {
    dateRange: {
      startDate: dayjs().subtract(7, 'days').toDate(),
      endDate: dayjs().toDate()
    },
    authToken: null,
    pkce: null,
    appVersion: null,
    username: null,
    currentLocation: null,
    currentCoreLocation: null,
    currentCoreLocationName: null,
    currentCoreLocationObj: null,
    currentSite: null,
    loginRedirect: null,
    isImpersonating: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    // for subheader that shows when a form is dirty
    theDirtyDetails: {
      formChangeData: {},
      isDirty: false,
      maskedFormDataKeys: [], //maskedFormDataKeys: ['ispAccountPin','voipAutoAttendantPin','username','passPhrase'],
    },
    userProfile: null,
  },
  getters: {
    dateRange(state) {
      return state.dateRange
    },
    currentLocation(state) {
      return state.currentLocation
    },
    currentCoreLocation(state) {
      return state.currentCoreLocation
    },
    currentCoreLocationName(state) {
      return state.currentCoreLocationName
    },
    currentCoreLocationObj(state) {
      return state.currentCoreLocationObj
    },
    currentSite(state) {
      return state.currentSite
    },
    authToken(state) {
      return state.authToken
    },
    pkce(state) {
      return state.pkce
    },
    appVersion(state) {
      return state.appVersion
    },
    username(state) {
      return state.username
    },
    userProfile(state) {
      return state.userProfile
    },
    loginRedirect(state) {
      return state.loginRedirect
    },
    isImpersonating(state) {
      return state.isImpersonating
    },
    theDirtyDetails(){
      return state.theDirtyDetails
    }, 
  },
  mutations: {
    reset(state) {
      state.currentLocation = null
      state.currentCoreLocation = null
      state.currentCoreLocationName = null
      state.currentCoreLocationObj = null
      state.authToken = null
      state.pkce = null
      state.userProfile = null
      state.username = null
      state.appVersion = null
      state.isImpersonating = false
      state.theDirtyDetails = {
        formChangeData: {},
        isDirty: false,
        maskedFormDataKeys: [], //maskedFormDataKeys: ['ispAccountPin','voipAutoAttendantPin','username','passPhrase'],
      }
    },
    dateRange(state, value) {
      state.dateRange = value
    },
    authToken(state, value) {
      state.authToken = value
    },
    pkce(state, value) {
      state.pkce = value
    },
    appVersion(state, value) {
      state.appVersion = value
    },
    username(state, value) {
      state.username = value
    },
    userProfile(state, value) {
      state.userProfile = value
    },
    currentLocation(state, value) {
      state.currentLocation = value
    },
    currentCoreLocation(state, value) {
      state.currentCoreLocation = value
    },
    currentCoreLocationName(state, value) {
      state.currentCoreLocationName = value
    },
    currentCoreLocationObj(state, value) {
      state.currentCoreLocationObj = value
    },
    loginRedirect(state, value) {
      state.loginRedirect = value
    },
    isImpersonating(state, value) {
      state.isImpersonating = value
    },
    theDirtyDetails(state, value) {
      state.theDirtyDetails = value
    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
      state[variable] = value
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'vuex-portal',
    }),
  ],
}
